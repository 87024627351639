// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.border-success {
  border: 2px solid green !important;
}

.border-danger {
  border: 2px solid red !important;
}

.card {
  margin-top: 1rem;
}

.card-body p {
  margin-bottom: 0.5rem;
}

.list-unstyled {
  padding-left: 0;
}

.list-unstyled li {
  margin-bottom: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;AACpC;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".border-success {\n  border: 2px solid green !important;\n}\n\n.border-danger {\n  border: 2px solid red !important;\n}\n\n.card {\n  margin-top: 1rem;\n}\n\n.card-body p {\n  margin-bottom: 0.5rem;\n}\n\n.list-unstyled {\n  padding-left: 0;\n}\n\n.list-unstyled li {\n  margin-bottom: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
